<template>
  <div>
    <v-card flat class="rounded-t-0" :loading="loading" :to="link">
      <v-alert
        :prominent="!$vuetify.breakpoint.xs"
        class="ma-0 rounded-t-0 text-body-2"
        type="error"
        dense
        icon="mdi-plus"
        :color="color"
        text
      >
        <strong>
          CHF {{ balance ? balance.toFixed(2) : "keine Angabe" }} </strong
        ><br />
        Stand <DateValue :value="lastImport.date" />,
        <TimeValue :value="lastImport.time" />
      </v-alert>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import TimeValue from "common/components/TimeValue.vue";
export default defineComponent({
  components: { DateValue, TimeValue },
  props: ["link"],
  data() {
    return {
      loading: false,
      balance: false,
      lastImport: {},
    };
  },
  computed: {
    color() {
      return this.balance > 0.1 ? "success" : "error";
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const data = await this.apiGet({
        resource: "finance/printoverview",
        id: this.$_profilePerson.id,
      });
      this.balance = data.balance;
      this.lastImport = await this.apiList({
        resource: "finance/printimportstate",
      });
      this.loading = false;
      this.$emit("loaded");
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
